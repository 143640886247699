import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../redux/actions";
import { adminService } from "../services/adminService";
import { showWarning } from "../utils/commonFunctions";
import { apiUrl } from "../config/Config";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

function ResetPassword() {
  let location = useLocation();
  let params = useParams();
  const { register, formState: { errors }, handleSubmit } = useForm();
  //let user = useSelector((state) => state?.adminUser?.userData);
  let user = useSelector((state) => state.adminUser.user);
  const tenantId = useSelector((st) => st.adminUser?.tenantInfo?.tenantId);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [accountId, setAccountId] = useState('');
  const [loginStatus, setLoginStatus] = useState(false);
  const [stripeAccountCompleted, setstripeAccountCompleted] = useState();
  const [inputs, setInputs] = useState({
    password: "",
    confimPassword: "",
    // tempToken : user.accessToken && user.accessToken,
    userUniqueId: user.accessToken && user.userDetails.userUniqueId,
  });
  const [loading, setLoading] = useState(false);
  const [tenantName, setTenantName] = useState('');
  let billingData = useSelector((state) => state?.adminUser?.billingData);
  console.log("location reset", user, tenantId,billingData);
  useEffect(() => {
    if(tenantId !== undefined)
    {
    fetch(`${apiUrl}/misc/billing-company-detail?tenantId=${tenantId ? tenantId : location?.state?.tenantId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user?.accessToken ? user?.accessToken : location?.state?.loginDetail?.accessToken,
        'SessionId': user?.sessionId ? user?.sessionId : location?.state?.loginDetail?.sessionId,
        'X-TenantID': tenantId ? tenantId : location?.state?.tenantId
      }
    })
      //.then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log('Success:', data.data, data?.data?.stripeAccountCompleted === false);
        // if (data?.data?.stripeAccountId === null || data?.data?.stripeAccountCompleted === false) {
        //   history({ pathname: "/add-account", state: { loginDetail: res, tenantId: tenantinfo?.tenantId, accountId: data?.data?.stripeAccountId, stripeAccountCompleted: data?.data?.stripeAccountCompleted } });
        // } else {
        //   dispatch(allActions.adminAction.logIn(res));
        //   history("/dashboard");
        // }
        dispatch(allActions.adminAction.stripeAccount(data?.data?.stripeAccountId));
        if (data?.status === "Success") {
          setLoginStatus(true);
          setAccountId(data?.data?.stripeAccountId);
          setstripeAccountCompleted(data?.data?.stripeAccountCompleted);
          setTenantName(data?.data?.name);
        } else {
          //toast.error(res.message+"Please ");
          setLoginStatus(false);
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
    }
  }, [accountId, stripeAccountCompleted,tenantId,loginStatus])

  const onSubmit = () => {
    if (inputs.password !== inputs.confimPassword) {
      showWarning("password_notmatch");
    } else if (inputs.password && inputs.confimPassword) {
      try {
        if (params.tempToken) {
          let restPass = {
            newPassword: inputs.password,
            tempToken: params.tempToken,
            userUniqueId: params.userUniqueId,
          };
          console.log(restPass);
          adminService.restPass(restPass).then(
            (res) => {
              if (res.status === "Success") {
                //    let user = JSON.parse(localStorage.getItem('user'));
                //    user.userDetails = res.data;
                //    dispatch(allActions.adminAction.logIn(user));
                toast.success(res.message);
                setTimeout(() => {
                  history("/login/");
                }, 2000);
              } else {
                toast.error(res.message);
              }
            },
            (error) => {
              console.log("Error >>>>>>>", error);
            }
          );
        } else {
          adminService.resetPassword(inputs).then(
            (res) => {
              if (res.status === "Success") {
                let user = JSON.parse(localStorage.getItem("user"));
                user.userDetails = res.data;
                //dispatch(allActions.adminAction.logIn(user));
                //console.log("user role", user?.userDetails?.roles?.[0] === "ROLE_THERAPIST", user?.userDetails?.roles?.[0] === "ROLE_RCE" && user?.userDetails?.superTenantAdmin === false && accountId === null);
                if (user?.userDetails?.roles?.[0] === "ROLE_FAMILY" && user?.userDetails?.creditCardAuthorization === false && user?.userDetails?.paymentInfo === false) {
                  history("/card-login");
                  //history({ pathname: "/card-login", state: { tenantName: tenantName, loginDetail: user, tenantId: tenantId, user: user } });
                } else if (user?.userDetails?.roles?.[0] === "ROLE_RCE" && user?.userDetails?.superTenantAdmin && accountId === null && stripeAccountCompleted === false) {
                  history({ pathname: "/add-account", state: { loginDetail: user, tenantId: tenantId, accountId: accountId, stripeAccountCompleted: stripeAccountCompleted } });
                } else if (user?.userDetails?.roles?.[0] === "ROLE_RCE" && user?.userDetails?.superTenantAdmin === false && accountId === null) {
                  history("/admin-normal-account");
                } else if (user?.userDetails?.roles?.[0] === "ROLE_THERAPIST") {
                  dispatch(allActions.adminAction.logIn(user));
                  history({ pathname: "/dashboard" });
                } else if (user?.userDetails?.roles?.[0] === "ROLE_INTERPRETER") {
                  dispatch(allActions.adminAction.logIn(user));
                  history({ pathname: "/dashboard" });
                }
                else {
                  if (loginStatus) {
                    toast.success("Logged in successfully");
                    dispatch(allActions.adminAction.logIn(user));
                    history({ pathname: "/dashboard" });
                  } else {
                    toast.error('Something went wrong. Please try again later.');
                  }

                  // setTimeout(() => {
                  //   history("/dashboard/");
                  // }, 2000);
                }
              } else {
                toast.error(res.message);
              }
            },
            (error) => {
              console.log("Error >>>>>>>", error);
            }
          );
        }
      } catch (error) { }
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  useEffect(() => {
    setInputs(inputs);
  }, [inputs]);

  return (
    <>
      <div className="col">
        <div className="container p-0">
          <div className="row align-items-center login-page">
            <div className="col-md-6 no-padding p-0">
              <div className="log-wrapper-left">
                <img alt=""
                  src={
                    params.tempToken
                      ? "/resources/assets/Mask Group 2.png"
                      : "../resources/assets/Mask Group 2.png"
                  }
                  className="mask-login-left"
                />
                <img alt=""
                  src={
                    params.tempToken
                      ? "/resources/assets/MIS-1.png"
                      : "../resources/assets/MIS-1.png"
                  }
                  className="login-logo"
                />
              </div>
              <img alt=""
                src={
                  params.tempToken
                    ? "/resources/assets/pass-left.png"
                    : "../resources/assets/pass-left.png"
                }
                className="left-img img-fluid"
              />
              <img alt=""
                src={
                  params.tempToken
                    ? "/resources/assets/MIS-1.png"
                    : "../resources/assets/MIS-1.png"
                }
                className="copyright-image "
              />
              <span className="copyright-text">Copyright @RCE 2021</span>
            </div>
            <div className="col-md-6 no-padding">
              <div className="row valign_wrapper padd-b-60-all ">
                <div className="custom-table-view-login">
                  <div className="col-lg-12 min-hei-250-all">
                    <div className="text-center login">
                      <div className="form-signin-wrapper">
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="custom-form form-custome-elements floting-form"
                        >
                          <h1 className="h3 mb-3 font-weight-normal text-left">
                            Reset Your Password
                          </h1>
                          {/* <div className="form-group">
        <input type="text" id="name" className="form-control" required />
        <label className="form-control-placeholder" for="name">Name</label>
      </div> */}
                          <div className="form-group">
                            <input
                              {...register('password',{
                                required: true,
                                minLength: 8,
                                maxLength: 16,
                                onChange:(e)=>handleChange(e)
                              })}
                              name="password"
                              //onChange={handleChange}
                              defaultValue={inputs.password}
                              type="password"
                              id="password"
                              className={`form-control ${errors.password && "is-invalid"
                                }`}
                              required
                            />
                            <label
                              className="form-control-placeholder"
                              for="inputEmail"
                            >
                              Enter New Password
                            </label>
                            {errors.password &&
                              errors.password.type === "required" && (
                                <div className="invalid-feedback-custom">
                                  Field is required
                                </div>
                              )}
                            {errors.password?.type === "minLength" && (
                              <div className="invalid-feedback-custom">
                                Password must contain minimum 8 characters
                              </div>
                            )}
                            {errors.password?.type === "maxLength" && (
                              <div className="invalid-feedback-custom">
                                Password must contain maximum 16 characters
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <input
                              name="confimPassword"
                              //onChange={handleChange}
                              defaultValue={inputs.confimPassword}
                              type="password"
                              id="confimPassword"
                              className={`form-control ${errors.confimPassword && "is-invalid"
                                }`}
                              {...register('confimPassword',{ required: true,onChange:(e)=>handleChange(e) })}
                              required
                            />
                            <label
                              className="form-control-placeholder"
                              for="confimPassword"
                            >
                              Confirm New Password
                            </label>
                            {errors.confimPassword &&
                              errors.confimPassword.type === "required" && (
                                <div className="invalid-feedback-custom">
                                  Field is required
                                </div>
                              )}
                          </div>

                          <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                              <button
                                className="btn custom-btn-green"
                                type="submit"
                                disabled={loading}
                                block={true}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
