import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import InjectCheckOutLogin from '../../pages/AddCard';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const successMessage = () => {
  return (
    <div className="success-msg">
      <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
      </svg>
      <div className="title">Payment Successful</div>
    </div>
  )
}
function CardLogin(props) {
  let params = useParams();
  const [stripePromise, setStripePromise] = useState(null);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  //let accessToken = useSelector((state) => state?.adminUser?.userData?.accessToken);
  //let sessionId = useSelector((state) => state?.adminUser?.userData?.sessionId);
  let accountId = useSelector((state) => state?.adminUser?.accountData);
  //const tenantId = useSelector((st) => st.adminUser?.tenantInfo?.tenantId);
  let user = useSelector((state) => state?.adminUser?.userData);
  console.log("user reset", user);

  const getAccount = () => {
    console.log("accountId res", accountId)
    setStripePromise(loadStripe("pk_test_51L93VpSIqiHQiTzcCQtM3cCfikd7d53y6VK1PPjhdw1RdX2xnp8L1kvoe7asyYDWpXvYe4oJJluH0B9vPFsx39wE00zP8OSvpe", {
      stripeAccount: accountId,
    }))
  }
  useEffect(() => {
    //if (accountId !== undefined) {
      getAccount();
    //}
  }, [])
  console.log("stripePromise", stripePromise);
  return (
    <div className="col">
      <div className="container p-0">
        <div className="row align-items-center login-page">
          <div className="col-md-6 no-padding p-0">
            <div className="log-wrapper-left">
              <img alt=""
                src={
                  params.tempToken
                    ? "/resources/assets/Mask Group 2.png"
                    : "../resources/assets/Mask Group 2.png"
                }
                className="mask-login-left"
              />
              <img alt=""
                src={
                  params.tempToken
                    ? "/resources/assets/MIS-1.png"
                    : "../resources/assets/MIS-1.png"
                }
                className="login-logo"
              />
            </div>
            <img alt=""
              src={
                params.tempToken
                  ? "/resources/assets/pass-left.png"
                  : "../resources/assets/pass-left.png"
              }
              className="left-img img-fluid"
            />
            <img alt=""
              src={
                params.tempToken
                  ? "/resources/assets/MIS-1.png"
                  : "../resources/assets/MIS-1.png"
              }
              className="copyright-image "
            />
            <span className="copyright-text">Copyright @RCE 2021</span>
          </div>
          <div className="col-md-6 no-padding">
            <div className="row valign_wrapper padd-b-60-all ">
              <div className="custom-table-view-login">
                <div className="col-lg-12 min-hei-250-all">
                  <div className="login">
                    <div className="">
                      <h2 className="header-title">Add Card Details</h2>
                      <hr />
                      {paymentCompleted ? successMessage() : <React.Fragment>
                        <div className="order-md-1">
                          <Elements stripe={stripePromise}>
                            <InjectCheckOutLogin amount={2000} setPaymentCompleted={setPaymentCompleted} />
                          </Elements>
                        </div>
                      </React.Fragment>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardLogin;